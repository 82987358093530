import React from "react"
import Coder from "../images/coder.svg"
import SEO from "../components/seo"
import Typist from "react-typist"
import "../style/index.scss"
import { Link } from "gatsby"

const IndexPage = () => (
  <>
    <SEO title="Inzimam Ul Haq" />
    <div className="coder-svg">
      <Coder className="opac" />
    </div>

    <a
      href="https://blog.inhaq.me/"
      target="_blank"
      rel="noopener noreferrer"
      aria-label="blog"
      className="blog"
    >
      Visit my blog &rarr;
    </a>

    <div className="details">
      <h1 className="lg-name">
        <span className="first-name">inzimam</span> ul haq
        <span className="lg-dot">.</span>
      </h1>
      <h3 className="title">Full Stack Developer</h3>
      <h5 className="work-details">
        Creator and contributor to Open Source Software
      </h5>
      <h5 className="work-details">
        Independent Contractor, helping teams with mobile & web technologies
      </h5>
      <Typist className="MyTypist">
        <span>I build websites</span>
        <Typist.Backspace count={5} delay={500} />
        <span> applications</span>
        <Typist.Backspace count={22} delay={500} />
        <span>like learning</span>
        <Typist.Backspace count={8} delay={500} />
        <span>gardening</span>
        <Typist.Backspace count={9} delay={500} />
        <span>bicycling</span>
        <Typist.Backspace count={9} delay={500} />
        <span>to travel</span>
        <Typist.Backspace count={16} delay={500} />
        <span>
          Hi{" "}
          <span role="img" aria-label="wave">
            👋🏻
          </span>
          , I'm Inzi, nice to meet you
        </span>
      </Typist>
      <Link to="/contact/">
        <button className="prime">Hire me</button>
      </Link>
      <Link to="/journey/">
        <button className="second">Know more</button>
      </Link>
    </div>
  </>
)

export default IndexPage
